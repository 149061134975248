<template>
  <a-drawer width="85%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-row type="flex" justify="center" align="top">
      <a-col :span="8">
        <img :src="form.logo" style="width: 300px;height: 300px" />
      </a-col>
      <a-col :span="8" style="font-size: 20px">
        <div>
          <span style="color:#333;font-weight: 700">Marketing Name:</span>
          {{form.name}}</div>
        <div style="margin-top: 10px">
          <span style="color:#333;font-weight: 700">Product Model#:</span>
          {{ form.productModel }}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700">Variant Code:</span>
          {{ form.variantCode }}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700"> Product ID:</span>
          {{ form.deviceId }}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700">Business Type:</span>
          {{form.businessTypeName}}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700">Product Type:</span>
          {{ form.productTypeName }}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700">Compatibility:</span>
          {{form.type === 0 ? 'OMNI NFC' : 'OMNI Hub'}}</div>
        <div style="margin-top: 10px" v-if="form.isTank == 1"><span style="color:#333;font-weight: 700">Tank Size:</span>
          {{ form.tankSizeValue }}</div>
      </a-col>
      <a-col :span="8" style="font-size: 20px">
        <!--        <div>Client:Client Company Name</div>-->
        <!--        <div>Brand:Brand Name</div>-->
        <div><span style="color:#333;font-weight: 700">Brand Product  Model#:</span>
          {{form.productModel}}</div>
        <div style="margin-top: 10px"><span style="color:#333;font-weight: 700">Brand Marketing Name:</span>
          {{ form.brandMarkingName }}</div>
      </a-col>
    </a-row>
    <div style="height:50px;"></div>
    <a-tabs type="card" v-model="typeIndex" style="width: 1100px">
      <a-tab-pane key="0"  v-if="form.list.length >= 1" tab="Light" >
        <myCustomEcharts id="myCustomEchartss1" :width="'1000px'" :height="'200px'" :option="curvesListDataArray[0]"></myCustomEcharts>
      </a-tab-pane>
      <a-tab-pane key="1" v-if="form.list.length >= 2" tab="Smooth">
        <myCustomEcharts id="myCustomEchartss2" :width="'1000px'" :height="'200px'" :option="curvesListDataArray[1]"></myCustomEcharts>
      </a-tab-pane>
      <a-tab-pane key="2" v-if="form.list.length >= 3" tab="Strong">
        <myCustomEcharts id="myCustomEchartss3" :width="'1000px'" :height="'200px'" :option="curvesListDataArray[2]"></myCustomEcharts>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
// import { getGoods, addGoods, updateGoods } from '@/api/cigarette/goods'
import {getSkuCurves} from "@/api/cigarette/skuCurves";
import {getSku} from "@/api/cigarette/sku";
import {getSku as getGoodSku} from "@/api/cigarette/goodSku";
import {getGoods} from '@/api/cigarette/userGoods'
import myCustomEcharts from "./myCustomEcharts";
export default {
  name: 'UserGoodsDetail',
  props: {
  },
  components: {
    myCustomEcharts
  },
  data () {
    return {
      typeIndex:"0",
      curvesListDataArray:[{
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
        },
        grid: {
          top:"20px",
          left:"50px",
          bottom:"20px",
          right:"20px"
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0,0],
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top',

            },
          }
        ]
      },{
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
        },
        grid: {
          top:"20px",
          left:"50px",
          bottom:"20px",
          right:"20px"
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0,0],
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top',

            },
          }
        ]
      },{
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
        },
        grid: {
          top:"20px",
          left:"50px",
          bottom:"20px",
          right:"20px"
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0,0],
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top',

            },
          }
        ]
      }],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        skuId: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      },
      goodSkuData:{

      },
      deviceId:""
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        skuId: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Product info'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.deviceId = row.deviceId;
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGoods({"deviceId":row.deviceId}).then(response => {
        // getGoodSku({
        //   id:response.data.skuId
        // }).then(res => {
        //   this.goodSkuData = res.data;
        // })
        this.form = response.data
        response.data.list.forEach(i => {
          if(i.level === 0) {
            this.curvesListDataArray[0].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          } else if(i.level === 1) {
            this.curvesListDataArray[1].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          } else if(i.level === 2) {
            this.curvesListDataArray[2].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          }
        })
        this.open = true
        this.formTitle = 'Product info'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGoods(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoods(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

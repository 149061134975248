import request from '@/utils/request'


// 查询新-规格曲线列表
export function listSkuCurves(query) {
  return request({
    url: '/cigarette/goods-sku-curves/list',
    method: 'get',
    params: query
  })
}

// 查询新-规格曲线分页
export function pageSkuCurves(query) {
  return request({
    url: '/cigarette/goods-sku-curves/page',
    method: 'get',
    params: query
  })
}

// 查询新-规格曲线详细
export function getSkuCurves(data) {
  return request({
    url: '/cigarette/goods-sku-curves/detail',
    method: 'get',
    params: data
  })
}

// 新增新-规格曲线
export function addSkuCurves(data) {
  return request({
    url: '/cigarette/goods-sku-curves/add',
    method: 'post',
    data: data
  })
}

// 修改新-规格曲线
export function updateSkuCurves(data) {
  return request({
    url: '/cigarette/goods-sku-curves/edit',
    method: 'post',
    data: data
  })
}

// 删除新-规格曲线
export function delSkuCurves(data) {
  return request({
    url: '/cigarette/goods-sku-curves/delete',
    method: 'post',
    data: data
  })
}
